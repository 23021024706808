import React, { createContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ObjectID from 'bson-objectid';
import _ from 'lodash';
import axios from 'axios';

import {
  bcItemsList,
  bcPortalItemsList,
  addToCart,
  adminGetPortalPricing,
} from '../actions';
import { scrKitMapper } from '../utils/kitMapper';

const scrState = {
  _id: '',
  screenId: '',
  screenName: '',
  options: {
    jobColor: '',
    "Mounting Angle": "Yes"
  },
  measurements: {
    width: 36,
    widthFraction: 0,
    dropLeft: 72,
    dropLeftFraction: 0,
    dropRight: 72,
    dropRightFraction: 0,
    widthDisplay: '',
    dropLeftDisplay: '',
    dropRightDisplay: '',
  },
  cartCopies: 1,
  meshOnly: false,
  meshOnlyReason: '',
  price: 0,
  details: '',
};

const rfState = {
  _id: '',
  roofId: '',
  roofName: '',
  colors: {
    frameColor: '',
    bladeColorRal: '',
    frameColorRal: '',
    bladeColor: '',
    postColor: '',
  },
  motorType: '',
  motorQuantity: 1,
  frames: [],
  beamMs: [],
  beamAs: [],
  blades: [],
  posts: [],
  addParts: [],
  cartCopies: 1,
  price: 0,
  details: '',
  file: null,
};

const StoreContext = createContext({
  isAdmin: Boolean,
  isOrder: Boolean,
  bcItemsLoading: Boolean,
  bcItemsError: '',
  portalItemsLoading: Boolean,
  portalItemsError: '',
  quoteLoading: Boolean,
  orderCreated: Boolean,
  error: '',
  cartInfo: {},
  orderInfo: {},
  createdOrder: {},
  bcScreenKits: [],
  bcRoofKits: [],
  bcParts: [],
  portalScreenKits: [],
  portalRoofKits: [],
  portalParts: [],
  portalCategories: [],
  scr: scrState,
  rf: rfState,
  setIsOrder: () => {},
  setScr: (scr) => scrState,
  setRf: (rf) => rfState,
  handleAddScrToCart: (e, copies) => {},
  handleAddRfToCart: (e, copies) => {},
  handleAddPrtToCart: (pId, number, pName, counter, pPrice) => {},
  handleAddFramesToRoof: (qty) => {},
  handleChangeFrame: (e, index) => {},
  handleAddBeamMsToRoof: (qty) => {},
  handleChangeBeamM: (e, index) => {},
  handleAddBeamAsToRoof: (qty) => {},
  handleChangeBeamA: (e, index) => {},
  handleAddBladesToRoof: (qty) => {},
  handleAddBeamXsToRoof: (qty) => {},
  handleChangeBeamX: (e, index) => {},
  handleChangeBlade: (e, index) => {},
  handleAddPostsToRoof: (qty) => {},
  handleChangePost: (e, index) => {},
  handleAddPartsToRoof: (qty) => {},
  handleChangePart: (e, index) => {},
});

const StoreContextProvider = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // const { bc_customer_priceGroup: salesCode } = userInfo;
  const bcItems = useSelector((state) => state.bcItemsList);
  const {
    bcScreenKits,
    bcRoofKits,
    bcParts,
    loading: bcItemsLoading,
    error: bcItemsError,
  } = bcItems;
  const bcPortalItems = useSelector((state) => state.bcPortalItemsList);
  const {
    portalScreenKits,
    portalRoofKits,
    portalParts,
    portalCategories,
    loading: portalItemsLoading,
    error: portalItemsError,
  } = bcPortalItems;
  const cart = useSelector((state) => state.cart);
  const { cartInfo, orderInfo } = cart;
  const bcSalesQuoteInit = useSelector((state) => state.bcSalesQuoteInit);
  const { loading: quoteLoading } = bcSalesQuoteInit;
  const orderCreate = useSelector((state) => state.orderCreate);
  const { success, order } = orderCreate;
  const adminPortalPricing = useSelector((state) => state.adminPortalPricing);
  const { portalPricings } = adminPortalPricing;

  const [scr, setScr] = useState(scrState);
  const [rf, setRf] = useState(rfState);
  const [extraDetails, setExtraDetails] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isOrder, setIsOrder] = useState(false);
  const [orderCreated, setOrderCreated] = useState(false);
  const [createdOrder, setCreatedOrder] = useState({});

  // console.log(bcPortalItems);

  useEffect(() => {
    if (!userInfo) {
      history.push('/login');
    } else {
      setIsAdmin(userInfo?.isAdmin);
    }
    if (!_.isEmpty(orderInfo?.user)) {
      setIsOrder(true);
      setExtraDetails(orderInfo?.extraDetails);
    }
    if (success) {
      setOrderCreated(true);
      setCreatedOrder(order);
    }
    dispatch(bcItemsList());
    dispatch(bcPortalItemsList('parts'));
    dispatch(adminGetPortalPricing());
  }, [userInfo, orderInfo, history, success, order, dispatch]);

  let isCart = {};
  if (!_.isEmpty(cartInfo?.user)) {
    // console.log('cartInfo', cartInfo);
    // console.log('cart', cart);
    // console.log('userInfo', userInfo);
    isCart = {
      _id: cartInfo?._id,
      cartId: cartInfo?.cartId,
      orderId: cartInfo?.orderId,
      orderName: cartInfo?.orderName,
      orderNumber: cartInfo?.orderNumber,
      user: cartInfo?.user,
      userName: cartInfo?.userDetails?.userName,
      email: cartInfo?.userDetails?.email,
      bc_customer_id: cartInfo?.userDetails?.bc_customer_id,
      bc_customer_displayName: cartInfo?.userDetails?.bc_customer_displayName,
      bc_customer_priceGroup: cartInfo?.userDetails?.bc_customer_priceGroup,
      bc_customer_tierRate: cartInfo?.userDetails?.bc_customer_tierRate,
      status: cartInfo?.status,
      shippingAgent: cartInfo?.shippingAgent,
      trackingNumber: cartInfo?.trackingNumber,
    };
  }

  const handleChangePost = (e, index) => {
    const { name, value } = e.target;
    const posts = [...rf.posts];
    posts[index][name] = value;
    setRf({ ...rf, posts });
  };
  const handleAddPostsToRoof = (inPosts) => {
    const posts = inPosts?.length > 0 ? inPosts : [];
    posts.push({
      postId: '',
      postStyle: '',
      postLength: '',
    });
    return posts;
  };

  const handleChangeFrame = (e, index) => {
    // console.log(e.target);
    const { name, value } = e.target;
    const frames = [...rf.frames];
    frames[index][name] = value;
    setRf({ ...rf, frames });
    // console.log(rf);
  };
  const handleAddFramesToRoof = (inFrames) => {
    const frames = inFrames?.length > 0 ? inFrames : [];
    frames.push({
      frameId: '',
      frameType: '',
      frameFeet: 0,
      frameInches: 0,
    });
    return frames;
  };
  const handleChangeBeamM = (e, index) => {
    const { name, value } = e.target;
    const beamMs = [...rf.beamMs];
    beamMs[index][name] = value;
    setRf({ ...rf, beamMs });
  };
  const handleAddBeamMsToRoof = (inBeamMs) => {
    const beamMs = inBeamMs?.length > 0 ? inBeamMs : [];
    beamMs.push({
      beamId: '',
      beamType: '',
      beamFeet: 0,
      beamInches: 0,
    });
    return beamMs;
  };
  const handleChangeBeamA = (e, index) => {
    const { name, value } = e.target;
    const beamAs = [...rf.beamAs];
    beamAs[index][name] = value;
    setRf({ ...rf, beamAs });
  };
  const handleAddBeamAsToRoof = (inBeamAs) => {
    const beamAs = inBeamAs?.length > 0 ? inBeamAs : [];
    beamAs.push({
      beamId: '',
      beamType: '',
      beamFeet: 0,
      beamInches: 0,
    });
    return beamAs;
  };
  const handleChangeBeamX = (e, index) => {
    const { name, value } = e.target;
    const beamXs = [...rf.beamXs];
    beamXs[index][name] = value;
    setRf({ ...rf, beamXs });
  };
  const handleAddBeamXsToRoof = (inBeamXs) => {
    const beamXs = inBeamXs?.length > 0 ? inBeamXs : [];
    beamXs.push({
      beamId: '',
      beamType: '',
      beamFeet: 0,
      beamInches: 0,
    });
    return beamXs;
  };

  const handleChangeBlade = (e, index) => {
    const { name, value } = e.target;
    const blades = [...rf.blades];
    blades[index][name] = value;
    setRf({ ...rf, blades });
  };
  const handleAddBladesToRoof = (inBlades) => {
    const blades = inBlades?.length > 0 ? inBlades : [];
    blades.push({
      bladeId: '',
      bladeType: '',
      bladeFeet: 0,
      bladeInches: 0,
      bladeQty: 1,
    });
    return blades;
  };

  const handleChangePart = (e, index) => {
    const { name, value } = e.target;
    const addParts = [...rf.addParts];
    addParts[index][name] = value;
    setRf({ ...rf, addParts });
  };
  const handleAddPartsToRoof = (inParts) => {
    const addParts = inParts?.length > 0 ? inParts : [];
    addParts.push({
      partId: '',
      partName: '',
      partQty: 1,
    });
    return addParts;
  };

  const measurementDisplay = (num, fraction) => {
    return Number(fraction) === 0 ? `${num}"` : `${num} ${fraction}"`;
  };

  const applyCustomerTierRate = (price) => {
    const userTierRate = userInfo?.bc_customer_tierRate;
    const customerTierRate = cartInfo?.userDetails?.bc_customer_tierRate;
    if (
      customerTierRate === undefined ||
      customerTierRate === null ||
      customerTierRate <= 0 ||
      userTierRate === undefined ||
      userTierRate === null ||
      userTierRate <= 0
    ) {
      return price;
    }
    if (customerTierRate === userTierRate) {
      return price;
    }
    const calculatedPrice = (price / userTierRate) * customerTierRate;
    return calculatedPrice;
  };

  const handleAddScrToCart = (e, scr) => {
    e.preventDefault();
    let screen = {};
    const screenCount = cartInfo.screens.length
    let copies = Number(
      scr.cartCopies && scr.cartCopies > 0 ? scr.cartCopies : 1
    );
    if(scr.meshOnly){
      scr.options = {
        materialType: scr.options.materialType,
        [`${scr.options.materialType} Color`]: scr.options[`${scr.options.materialType} Color`],
      }
    }
    let i = copies;
    while (i > 0) {
      const mappedScrKits = scrKitMapper(scr, bcScreenKits, portalPricings);
      screen = {
        _id: scr._id,
        screenId:
          scr.screenId?.length > 0 ? scr.screenId : ObjectID().toString(),
        screenName: scr.screenName? scr.screenName: `Screen #${screenCount + 1}`,
        options: scr.options,
        measurements: {
          width: scr.measurements.width,
          widthFraction: scr.measurements.widthFraction,
          dropLeft: scr.measurements.dropLeft,
          dropLeftFraction: scr.measurements.dropLeftFraction,
          dropRight: scr.measurements.dropRight,
          dropRightFraction: scr.measurements.dropRightFraction,
          widthDisplay: measurementDisplay(
            scr.measurements.width,
            scr.measurements.widthFraction
          ),
          dropLeftDisplay: measurementDisplay(
            scr.measurements.dropLeft,
            scr.measurements.dropLeftFraction
          ),
          dropRightDisplay: measurementDisplay(
            scr.measurements.dropRight,
            scr.measurements.dropRightFraction
          ),
        },
        mappedScrKits: mappedScrKits.mappedScrKits,
        meshOnly: scr.meshOnly,
        meshOnlyReason: scr.meshOnlyReason,
        price: applyCustomerTierRate(mappedScrKits.price),
        // price: mappedScrKits.price,
        details: scr.details,
      };
      dispatch(addToCart(isCart, screen, null, null));
      i--;
      if (i === 0) {
        setScr(scrState);
      }
    }
  };

  const handleAddRfToCart = async  (e, rf) => {
    e.preventDefault();
    let roof = {};
    const pricingInfo = {
      roof: rf,
      kits: bcRoofKits
    } 
    console.log(pricingInfo)
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const mappedRfKitsRes  = await axios.post(`/api/orders/getorderprices`, pricingInfo, config);
    const mappedRfKits = mappedRfKitsRes.data
    roof = {
      _id: rf._id,
      roofId: rf.roofId?.length > 0 ? rf.roofId : ObjectID().toString(),
      roofName: rf.roofName,
      colors: {
        frameColor: rf.colors?.frameColor,
        bladeColor: rf.colors?.bladeColor,
        postColor: rf.colors?.postColor,
        frameColorRal: rf.colors?.frameColorRal,
        bladeColorRal: rf.colors?.bladeColorRal,
      },
      motorType: rf?.motorType,
      motorQuantity: Number(rf?.motorQuantity),
      frames: rf.frames?.map((frame) => ({
        frameId:
          frame.frameId?.length > 0 ? frame.frameId : ObjectID().toString(),
        frameType: frame.frameType,
        frameFeet: Number(frame.frameFeet),
        frameInches: Number(frame.frameInches),
      })),
      beamMs: rf.beamMs?.map((beamM) => ({
        beamId: beamM.beamId?.length > 0 ? beamM.beamId : ObjectID().toString(),
        beamType: beamM.beamType,
        beamFeet: Number(beamM.beamFeet),
        beamInches: Number(beamM.beamInches),
      })),
      beamAs: rf.beamAs?.map((beamA) => ({
        beamId: beamA.beamId?.length > 0 ? beamA.beamId : ObjectID().toString(),
        beamType: beamA.beamType,
        beamFeet: Number(beamA.beamFeet),
        beamInches: Number(beamA.beamInches),
      })),
      blades: rf.blades?.map((blade) => ({
        bladeId:
          blade.bladeId?.length > 0 ? blade.bladeId : ObjectID().toString(),
        bladeType: blade.bladeType,
        bladeFeet: Number(blade.bladeFeet),
        bladeInches: Number(blade.bladeInches),
        bladeQty: Number(blade.bladeQty),
      })),
      posts: rf.posts?.map((post) => ({
        postId: post.postId?.length > 0 ? post.postId : ObjectID().toString(),
        postStyle: post.postStyle,
        postLength: post.postLength,
      })),
      addParts: rf.addParts?.map((part) => ({
        partId: part.partId?.length > 0 ? part.partId : ObjectID().toString(),
        partName: part.partName,
        partQty: Number(part.partQty),
      })),
      mappedRfKits: mappedRfKits?.mappedRfKits,
      details: rf.details,
      price: applyCustomerTierRate(mappedRfKits.price),
      // price: mappedRfKits.price,
      file: rf.file ? rf.file : null,
      fileName: rf.file ? rf.fileName : '',
    };
    dispatch(addToCart(isCart, null, roof, null));
    setRf(rfState);
  };

  const handleAddPrtToCart = (pId, number, pName, counter, pPrice, inCart) => {


    // const price = pPrice?.find(
    //   (price) => price?.salesCode === salesCode
    // )?.unitPrice;
    let quantity = counter
    const foundItem = cartInfo.parts.find(item => item.id === pId);
    if(foundItem && !inCart){
      quantity = Number(quantity) + Number(foundItem.quantity)
    }
    let convertedPrice = pPrice
    if(typeof pPrice !== 'number'){
      convertedPrice = pPrice.find(obj => obj.salesCode === userInfo.bc_customer_priceGroup);
      convertedPrice = applyCustomerTierRate(convertedPrice).unitPrice
    }
    let part = {
      id: pId,
      number: number,
      displayName: pName,
      quantity: String(quantity),
      price: convertedPrice,
      inCart: inCart
      // price: pPrice,
    };
    dispatch(addToCart(isCart, null, null, part));
  };

  return (
    <StoreContext.Provider
      value={{
        isAdmin: isAdmin,
        isOrder: isOrder,
        bcItemsLoading: bcItemsLoading,
        bcItemsError: bcItemsError,
        portalItemsLoading: portalItemsLoading,
        portalItemsError: portalItemsError,
        quoteLoading: quoteLoading,
        orderCreated: orderCreated,
        bcScreenKits: bcScreenKits,
        bcRoofKits: bcRoofKits,
        bcParts: bcParts,
        portalScreenKits: portalScreenKits,
        portalRoofKits: portalRoofKits,
        portalParts: portalParts,
        portalCategories: portalCategories,
        scr: scr,
        rf: rf,
        extraDetails: extraDetails,
        cartInfo: cartInfo,
        orderInfo: orderInfo,
        createdOrder: createdOrder,
        setIsOrder: setIsOrder,
        setScr: setScr,
        setRf: setRf,
        setExtraDetails: setExtraDetails,
        handleAddScrToCart: handleAddScrToCart,
        handleAddRfToCart: handleAddRfToCart,
        handleAddPrtToCart: handleAddPrtToCart,
        handleAddFramesToRoof: handleAddFramesToRoof,
        handleChangeFrame: handleChangeFrame,
        handleAddBeamMsToRoof: handleAddBeamMsToRoof,
        handleChangeBeamM: handleChangeBeamM,
        handleAddBeamAsToRoof: handleAddBeamAsToRoof,
        handleChangeBeamA: handleChangeBeamA,
        handleAddBeamXsToRoof: handleAddBeamXsToRoof,
        handleChangeBeamX: handleChangeBeamX,
        handleAddBladesToRoof: handleAddBladesToRoof,
        handleChangeBlade: handleChangeBlade,
        handleAddPostsToRoof: handleAddPostsToRoof,
        handleChangePost: handleChangePost,
        handleAddPartsToRoof: handleAddPartsToRoof,
        handleChangePart: handleChangePart,
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreContextProvider };