import React, { useContext, useState, useEffect } from 'react';

import { StoreContext } from '../context';
import { Loader, Message, DropDirection } from '../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { InfoDialog } from '.';
import { Info } from '@material-ui/icons';

import {
  makeStyles,
  TextField,
  Button,
  Grid,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Tabs,
  Tab,
  Tooltip,
  Avatar,
  Box,
} from '@material-ui/core';

import ScreenTabIconImage from '../assets/images/screensIcon.png';
import ScreenSideIndicatorImage from '../assets/images/retractableScreens1.png';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
  submit: {
    marginTop: theme.spacing(1),
  },
  marg: {
    margin: theme.spacing(1),
  },
  formControl: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
    marginRight: theme.spacing(),
    width: '99%',
  },
  mountingAngle: {
    margin: theme.spacing(1),
  },
  btns: {
    display: 'flex',
    flexDirection: 'row',
  },
  splitCol: {
    display: 'flex',
  },
  screenTabs: {
    fontSize: "18px",
    paddingRight: "40px",
    paddingLeft: "40px"
  },
  screenSideIndicator: {
    maxWidth: '75%',
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  headerBoxText: {
    fontSize: '18px'
  },
}));

const DynamicAttributes = ({ kitAttributes, scr, setScr, size }) => {
  const renderKitAttributes = (kitAttributes) => {
    return kitAttributes
      ?.filter((attr) => attr.attributeName.startsWith('Kit-'))
      ?.map((attr) => {
        const truncatedName = attr.attributeName.replace('Kit-', '');
        return (
          <Grid item xs={size ? size : 4} key={truncatedName}>
            <InputLabel shrink>{truncatedName}</InputLabel>
            <Select
              value={scr.options[truncatedName] || ''}
              fullWidth
              required
              onChange={(e) =>
                setScr({
                  ...scr,
                  options: {
                    ...scr.options,
                    [truncatedName]: e.target.value,
                  },
                })
              }
            >
              {attr.attributeValues.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        );
      });
  };

  return <>{renderKitAttributes(kitAttributes)}</>;
};

const ScreenMaterial = ({
  kitAttributes,
  scr,
  setScr,
  materialType,
  setMaterialType,
}) => {
  const renderKitAttributes = (kitAttributes) => {
    return kitAttributes
      ?.filter((attr) =>
        attr?.attributeName.startsWith(`Kit-${scr.options.materialType}`)
      )
      ?.filter((attr) => attr?.attributeName !== 'Kit-Material Type')
      ?.map((attr) => {
        const truncatedName = attr?.attributeName?.replace('Kit-', '');
        if (truncatedName.toLowerCase().includes("color")) {
          attr?.attributeValues.sort((a, b) => {
            const hasBlackA = a.toLowerCase().includes("black") ? 0 : 1;
            const hasBlackB = b.toLowerCase().includes("black") ? 0 : 1;

            return hasBlackA - hasBlackB;
          });
        }

        return (
          <Grid item xs={4} key={truncatedName}>
            <InputLabel shrink>{truncatedName}</InputLabel>
            <Select
              value={scr?.options[truncatedName] || ''}
              fullWidth
              onChange={(e) => {
                setScr({
                  ...scr,
                  options: {
                    ...scr?.options,
                    [truncatedName]: e.target.value,
                  },
                })

              }
              }
              required
            >
              {attr?.attributeValues?.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        );
      });
  };

  return (
    <>
      <Grid item xs={4}>
        <InputLabel shrink>Material Type</InputLabel>
        <Select
          value={scr?.options?.materialType || ''}
          required
          placeholder='Select an option'
          fullWidth
          onChange={(e) => {
            for (const key in scr.options) {
              if (key.includes(scr.options.materialType)) {
                delete scr.options[key];
              }
            }
            setMaterialType(e.target.value);
            setScr({
              ...scr,
              options: {
                ...scr?.options,
                materialType: e.target.value,
              },
            });
          }}
        >
          {kitAttributes
            ?.filter((k) => k?.attributeName === 'Kit-Material Type')
            .flatMap((k) => k?.attributeValues)
            .map((value) => (
              <MenuItem key={value} value={value}>
                {value}
              </MenuItem>
            ))}
        </Select>
      </Grid>
      {renderKitAttributes(kitAttributes)}
    </>
  );
};

const ScreenForm = ({ order, status }) => {
  const classes = useStyles();
  const ctx = useContext(StoreContext);


  const [materialType, setMaterialType] = useState('');
  const [motorTypeDisplay, setMotorTypeDisplay] = useState({});
  const [jobColorSize, setjobColorSize] = useState(4);
  const { portalScreenKits, scr, setScr } = ctx || {};
  const { Bar, Material, Motor } =
    portalScreenKits !== undefined && portalScreenKits;
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (Motor) {
      updateAttribute(ctx.scr.measurements.width, ctx.scr.measurements.dropLeft, ctx.scr.measurements.dropRight)
    }
  }, [Motor, ctx.scr.measurements.width, ctx.scr.measurements.dropLeft, ctx.scr.measurements.dropRight]);
  function updateAttribute(width, dropLeft, dropRight) {
    let newMotorDisplay = ["Standard - 21 RPM", "Fast - 34 RPM", "HD - 21 RPM", "Super HD - 16 RPM"]
    if (width >= 120 && width < 240 && (dropLeft < 132 && dropRight < 132)) {
      newMotorDisplay = ["Standard - 21 RPM", "HD - 21 RPM", "Super HD - 16 RPM"]
    } else if (width >= 240 && width < 300 || (width < 300 && (dropLeft >= 132 || dropRight >= 132))) {
      newMotorDisplay = ["HD - 21 RPM", "Super HD - 16 RPM"]
    } else if (width >= 300) {
      newMotorDisplay = ["Super HD - 16 RPM"]
    }
    if (!newMotorDisplay.includes(ctx.scr.options["Motor Type"])) {
      setScr({
        ...ctx.scr,
        options: {
          ...ctx.scr.options,
          ["Motor Type"]: '',
        },
      })
    }
    setMotorTypeDisplay({
      displayName2: Motor.displayName2,
      itemCategoryCode: Motor.itemCategoryCode,
      attributes: Motor.attributes.map(item => {
        if (item.attributeName === "Kit-Motor Type") {
          return {
            ...item,
            attributeValues: newMotorDisplay
          };
        }
        return item;
      })
    })
  }
  function resetMotor() {
    setMotorTypeDisplay({
      displayName2: motorTypeDisplay.displayName2,
      itemCategoryCode: motorTypeDisplay.itemCategoryCode,
      attributes: motorTypeDisplay.attributes.map(item => {
        if (item.attributeName === "Kit-Motor Type") {
          return {
            ...item,
            attributeValues: ["Standard - 21 RPM", "Fast - 34 RPM", "HD - 21 RPM", "Super HD - 16 RPM"]
          };
        }
        return item;
      })
    })
  }

  const setHousing = (field, value) => {
    const width = field === 'width' ? value : ctx.scr.measurements.width
    const dropLeft = field === 'dropLeft' ? value : ctx.scr.measurements.dropLeft
    const dropRight = field === 'dropRight' ? value : ctx.scr.measurements.dropRight
    updateAttribute(width, dropLeft, dropRight)
    if (width >= 264 ||
      dropLeft >= 132 ||
      dropRight >= 132) {
      ctx.setScr({
        ...ctx.scr,
        measurements: {
          ...ctx.scr.measurements,
          [field]: value,
        },
        options: {
          ...ctx.scr.options,
          ["Housing Size"]: '7',
          ["Housing Shape"]: 'Curved',
        },
      });
    } else {
      ctx.setScr({
        ...ctx.scr,
        measurements: {
          ...ctx.scr.measurements,
          [field]: value,
        },
      });
    }
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    ctx.setScr({ ...ctx.scr, meshOnly: newValue === 1 });
  };

  return (
    <div className={classes.paper}>
      <form
        className={classes.form}
        onSubmit={(e) => {
          ctx.handleAddScrToCart(e, ctx.scr)
          resetMotor()
        }
        }
      >
        <Box display="flex" justifyContent="center" mb={2}>
          <Tabs value={tabValue} onChange={handleTabChange}
            aria-label="material-or-other-tabs"
          >
            <Tab label="Complete Unit" className={classes.screenTabs} />
            <Tab label="Mesh / Vinyl Only" className={classes.screenTabs} />
          </Tabs>
        </Box>

        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              <Box flexGrow={1} className={classes.headerBox}>
                <Typography
                  color='inherit'
                  variant='h6'
                  className={classes.headerBoxText}
                >
                  Dimensions
                  {ctx.scr.meshOnly ? (
                    <Typography
                      color='error'
                      variant='subtitle2'
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        margin: 'auto',
                      }}
                    >
                      This is the width of the Housing, not the Mesh
                    </Typography>
                  ) : (
                    ''

                  )}
                </Typography>
                <InfoDialog type={'dimensions'} />

              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormControl>
                <TextField
                  fullWidth
                  required
                  type='number'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: 36 } }}
                  label='Width (Inches)'
                  value={ctx.scr.measurements.width === 0 ? '' : ctx.scr.measurements.width}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    const value = e.target.value === '' ? '' : Number(e.target.value);
                    setHousing("width", value)
                  }

                  }
                />

              </FormControl>

            </Grid>
            <Grid item xs={12} sm={1}>
              <FormControl>
                <InputLabel shrink></InputLabel>
                <Select
                  value={ctx.scr.measurements.widthFraction}
                  required
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      measurements: {
                        ...ctx.scr.measurements,
                        widthFraction: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={'1/8'}>1/8</MenuItem>
                  <MenuItem value={'1/4'}>1/4</MenuItem>
                  <MenuItem value={'3/8'}>3/8</MenuItem>
                  <MenuItem value={'1/2'}>1/2</MenuItem>
                  <MenuItem value={'5/8'}>5/8</MenuItem>
                  <MenuItem value={'3/4'}>3/4</MenuItem>
                  <MenuItem value={'7/8'}>7/8</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl>
                <TextField
                  fullWidth
                  required
                  type='number'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: 12 } }}
                  label='Drop Left (Inches)'
                  value={ctx.scr.measurements.dropLeft === 0 ? '' : ctx.scr.measurements.dropLeft}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    const value = e.target.value === '' ? '' : Number(e.target.value);
                    setHousing("dropLeft", value)
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={1}>
              <FormControl>
                <InputLabel shrink></InputLabel>
                <Select
                  value={ctx.scr.measurements.dropLeftFraction}
                  required
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      measurements: {
                        ...ctx.scr.measurements,
                        dropLeftFraction: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={'1/4'}>1/4</MenuItem>
                  <MenuItem value={'1/2'}>1/2</MenuItem>
                  <MenuItem value={'3/4'}>3/4</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormControl>
                <TextField
                  fullWidth
                  required
                  type='number'
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ inputProps: { min: 12 } }}
                  label='Drop Right (Inches)'
                  value={ctx.scr.measurements.dropRight === 0 ? '' : ctx.scr.measurements.dropRight}
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    const value = e.target.value === '' ? '' : Number(e.target.value);
                    setHousing("dropRight", value)
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={1}>
              <FormControl>
                <InputLabel shrink></InputLabel>
                <Select
                  value={ctx.scr.measurements.dropRightFraction}
                  required
                  onChange={(e) =>
                    ctx.setScr({
                      ...ctx.scr,
                      measurements: {
                        ...ctx.scr.measurements,
                        dropRightFraction: e.target.value,
                      },
                    })
                  }
                >
                  <MenuItem value={0}>0</MenuItem>
                  <MenuItem value={'1/4'}>1/4</MenuItem>
                  <MenuItem value={'1/2'}>1/2</MenuItem>
                  <MenuItem value={'3/4'}>3/4</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DropDirection
              l={ctx.scr.measurements.dropLeft || ''}
              lf={ctx.scr.measurements.dropLeftFraction || ''}
              r={ctx.scr.measurements.dropRight || ''}
              rf={ctx.scr.measurements.dropRightFraction || ''}
            />
          </Grid>
          {ctx.scr.meshOnly ? (
            ''
          ) : (

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <Box flexGrow={1} className={classes.headerBox}>
                  <Typography
                    color='inherit'
                    variant='h6'
                    className={classes.headerBoxText}
                  >
                    Housing
                  </Typography>
                  <Tooltip  title={
                    <div style={{ fontSize: 12 }}>
                      <span>5.5” (recommended when screens are less than 22’ wide and / or less than 11’ tall)</span>
                      <br />
                      <span>7” (required when screens are greater than 22’ wide or 11’ tall)</span>
                    </div>
                  } arrow>
                    <Box style={{ paddingLeft: 5, color: "secondary" }} component="span">
                    <Info color='secondary'  fontSize='small' />
                    </Box>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={12} sm={jobColorSize}>
                <FormControl fullWidth>
                  <InputLabel shrink>Housing Size</InputLabel>
                  <Select
                    margin='dense'
                    value={ctx.scr.options["Housing Size"] || ''}
                    required
                    onChange={(e) => {
                      if (e.target.value === "5.5") {
                        setScr({
                          ...ctx.scr,
                          options: {
                            ...ctx.scr.options,
                            ["Housing Size"]: e.target.value,
                            ["Housing Shape"]: "Square",
                          },
                        })
                      } else {
                        setScr({
                          ...ctx.scr,
                          options: {
                            ...ctx.scr.options,
                            ["Housing Size"]: e.target.value,
                            ["Housing Shape"]: "Curved",
                          },
                        })
                      }
                    }

                    }

                    disabled={
                      ctx.scr.measurements.width >= 264 ||
                      ctx.scr.measurements.dropLeft >= 132 ||
                      ctx.scr.measurements.dropRight >= 132
                    }
                  >
                    <MenuItem value="5.5">5.5"</MenuItem>
                    <MenuItem value="7">7"</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={jobColorSize}>
                <FormControl fullWidth>
                  <InputLabel shrink>Housing Shape</InputLabel>
                  <Select
                    margin='dense'
                    value={ctx.scr.options["Housing Shape"] || ''}
                    required
                    onChange={(e) =>
                      setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          ["Housing Shape"]: e.target.value,
                        },
                      })
                    }
                    disabled={
                      ctx.scr.measurements.width >= 264 ||
                      ctx.scr.measurements.dropLeft >= 132 ||
                      ctx.scr.measurements.dropRight >= 132 ||
                      ctx.scr.options["Housing Size"] === "7"
                    }
                  >
                    <MenuItem value={'Curved'}>Curved</MenuItem>
                    <MenuItem value={'Square'}>Square</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={jobColorSize}>
                <FormControl fullWidth>
                  <InputLabel shrink>Job Color</InputLabel>
                  <Select
                    margin='dense'
                    required={!ctx.scr.meshOnly}
                    disabled={ctx.scr.meshOnly}
                    value={ctx.scr.options.jobColor}
                    onChange={(e) => {
                      if (e.target.value === "MF") {
                        setjobColorSize(3)
                      } else {
                        delete scr.options['RAL Color'];
                        setjobColorSize(4)
                      }
                      ctx.setScr({
                        ...ctx.scr,
                        options: { ...ctx.scr.options, jobColor: e.target.value },
                      })
                    }
                    }
                  >
                    <MenuItem value={'Black'}>Black</MenuItem>
                    <MenuItem value={'White'}>White</MenuItem>
                    <MenuItem value={'Brown'}>Brown</MenuItem>
                    <MenuItem value={'Tan'}>Tan</MenuItem>
                    <MenuItem value={'Linen'}>Linen</MenuItem>
                    <MenuItem value={'Grey'}>Grey</MenuItem>
                    <MenuItem value={'MF'}>Custom *</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {ctx.scr.options.jobColor === 'MF' ? (
                <Grid style={{ paddingTop: '1px' }} item xs={12} sm={jobColorSize} >
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      required
                      type="number"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ maxLength: 4 }}
                      label="RAL Color"
                      value={ctx.scr.options["RAL Color"] === 0 ? "" : ctx.scr.options["RAL Color"]}
                      onFocus={(e) => e.target.select()}
                      onKeyDown={(e) => {
                        if (e.key === "e" || e.key === "E" || e.key === "." || e.key === "+") {
                          e.preventDefault();
                        }
                      }}
                      onChange={(e) => {
                        const value = e.target.value.slice(0, 4);
                        ctx.setScr({
                          ...ctx.scr,
                          options: { ...ctx.scr.options, "RAL Color": value },
                        });
                      }}
                    />

                  </FormControl>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          )}
          {ctx.scr.meshOnly ? (
            ''
          ) : (

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={12}>
                <Box flexGrow={1} className={classes.headerBox}>
                  <Typography
                    color='inherit'
                    variant='h6'
                    className={classes.headerBoxText}
                  >
                    Track
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Track Left</InputLabel>
                  <Select
                    margin='dense'
                    required
                    value={ctx.scr.options["Track Left"] || ''}
                    onChange={(e) =>
                      setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          ["Track Left"]: e.target.value,
                          ["Mounting Angle"]: e.target.value === "Standard" ? ctx.scr.options["Mounting Angle"] : 'No'
                        },
                      })
                    }
                  >
                    <MenuItem value="Standard">Standard</MenuItem>
                    <MenuItem value="Recessed">Recessed</MenuItem>
                    <MenuItem value="Cable Track">Cable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Track Right</InputLabel>
                  <Select
                    margin='dense'
                    required
                    value={ctx.scr.options["Track Right"] || ''}
                    onChange={(e) =>
                      setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          ["Track Right"]: e.target.value,
                          ["Mounting Angle"]: e.target.value === "Standard" ? ctx.scr.options["Mounting Angle"] : 'No'
                        },
                      })
                    }
                  >
                    <MenuItem value="Standard">Standard</MenuItem>
                    <MenuItem value="Recessed">Recessed</MenuItem>
                    <MenuItem value="Cable Track">Cable</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel shrink>Mounting Angle</InputLabel>
                  <Select
                    margin='dense'
                    required
                    value={ctx.scr.options["Mounting Angle"] || ''}
                    onChange={(e) =>
                      setScr({
                        ...ctx.scr,
                        options: {
                          ...ctx.scr.options,
                          ["Mounting Angle"]: e.target.value,
                        },
                      })
                    }
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value="No">No</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

            </Grid>

          )}

          <Grid item container xs={12} spacing={1} direction='row'>
            <Grid item xs={12}>
              <Box flexGrow={1} className={classes.headerBox}>
                <Typography
                  color='inherit'
                  variant='h6'
                  className={classes.headerBoxText}
                >
                  Screen
                </Typography>
              </Box>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <ScreenMaterial
                scr={scr}
                setScr={setScr}
                kitAttributes={Material?.attributes}
                materialType={materialType}
                setMaterialType={setMaterialType}
              />
              {ctx.scr.meshOnly ? (
                ''
              ) : (
                <DynamicAttributes
                  kitAttributes={Bar?.attributes}
                  scr={scr}
                  setScr={setScr}
                />
              )}

            </Grid>

          </Grid>

          {ctx.scr.meshOnly ? (
            ''
          ) : (
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12}>
                <Box flexGrow={1} className={classes.headerBox}>
                  <Typography
                    color='inherit'
                    variant='h6'
                    className={classes.headerBoxText}
                  >
                    Motor
                  </Typography>
                  <Tooltip title={
                    <div style={{ fontSize: 12 }}>
                      <span>Standard (recommended for screens less than 20’ wide)</span>
                      <br />
                      <span>Fast (available for screens less than 10’ wide and less than 10’ tall)</span>
                      <br />
                      <span>HD (required for screens greater than 20’ wide)</span>
                      <br />
                      <span>Super HD (required for screens greater than 25’ wide)</span>
                    </div>
                  } arrow>
                    <Box style={{ paddingLeft: 10 }} component="span">
                    <Info color='secondary'  fontSize='small' />
                    </Box>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <DynamicAttributes
                  kitAttributes={motorTypeDisplay?.attributes}
                  scr={scr}
                  setScr={setScr}
                  size={6}
                />
                <Grid item xs={6}  >
                  <FormControl fullWidth>
                    <InputLabel shrink>Motor Side</InputLabel>
                    <Select
                      value={ctx.scr.options["Motor Side"] || ''}
                      required
                      onChange={(e) =>
                        setScr({
                          ...ctx.scr,
                          options: {
                            ...ctx.scr.options,
                            ["Motor Side"]: e.target.value,
                          },
                        })
                      }
                    >
                      <MenuItem value="Left">Left</MenuItem>
                      <MenuItem value="Right">Right</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          )}


          <Grid item xs={12}>
            <FormControl
              className={classes.formControl}
              required
              fullWidth
              style={{ width: '100%' }}
            >
              <TextField
                fullWidth
                variant='outlined'
                multiline
                minRows={6}
                type='text'
                InputLabelProps={{ shrink: true }}
                label='Additional Details'
                value={ctx.scr.details}
                onFocus={(e) => e.target.select()}
                onChange={(e) =>
                  ctx.setScr({ ...ctx.scr, details: e.target.value })
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography color='error' variant='subtitle2'>
              <em>
                All additional items (remotes, angle for top slope, etc.) need
                to be ordered separately from the Parts & Extras tab.
              </em>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              type='submit'
              className={classes.submit}
              fullWidth
              variant='contained'
              color='primary'
              disabled={ctx.isAdmin && order && status === 'Rejected'}
              size='medium'
            >
              {order || ctx.scr.screenId.length > 0
                ? 'Update Order'
                : 'Add to Order'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const StoreScreens = ({ order, status }) => {
  const ctx = useContext(StoreContext);
  return (
    <>
      {ctx.loading ? (
        <Loader />
      ) : ctx.error ? (
        <Message severity='error'>{ctx.error}</Message>
      ) : (
        <ScreenForm order={order} status={status} />
      )}
    </>
  );
};

export { StoreScreens };

// <div className={classes.paper}>
//   <form
//     className={classes.form}
//     onSubmit={(e) => ctx.handleAddScrToCart(e, ctx.scr)}
//   >
//     <Grid container>
//       <Grid container item sm={12} md={12}>
//         <Box flexGrow={1} className={classes.headerBox}>
//           <Typography
//             color='inherit'
//             variant='h6'
//             style={{
//               display: 'flex',
//               alignItems: 'center',
//             }}
//           >
//             <Avatar src={ScreenTabIconImage} variant='square' /> Retractable
//             Screens
//           </Typography>
//         </Box>
//         <Box>
//           <img
//             src={ScreenSideIndicatorImage}
//             alt='screen side indicator'
//             className={classes.screenSideIndicator}
//           />
//         </Box>
//         <Grid item xs={12}>
//           <FormControl required fullWidth style={{ marginBottom: '5' }}>
//             <Typography>
//               {ctx.scr.meshOnly ? 'Mesh' : 'Screen'} Name
//             </Typography>
//             <TextField
//               fullWidth
//               required
//               type='text'
//               value={ctx.scr.screenName}
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               onFocus={(e) => e.target.select()}
//               onChange={(e) =>
//                 ctx.setScr({
//                   ...ctx.scr,
//                   screenName: e.target.value,
//                 })
//               }
//             />
//           </FormControl>
//           <FormControl style={{ marginBottom: '5' }}>
//             <FormControlLabel
//               style={{ paddingTop: '10px' }}
//               control={
//                 <Checkbox
//                   size='small'
//                   checked={ctx.scr.meshOnly}
//                   onChange={(e) => {
//                     ctx.setScr({
//                       ...ctx.scr,
//                       meshOnly: e.target.checked,
//                     });
//                   }}
//                 />
//               }
//               label='Mesh Only?'
//             />
//           </FormControl>
//         </Grid>
//         <Grid container item sm={12} md={6}>
//           <Typography style={{ paddingTop: '10px' }}>Options</Typography>
//           {/* <FormControl
//             className={classes.formControl}
//             fullWidth
//             style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
//           >
//             <InputLabel shrink>Housing Size</InputLabel>
//             <Select
//               margin='dense'
//               required={!ctx.scr.meshOnly}
//               disabled={ctx.scr.meshOnly}
//               value={ctx.scr.options.screenType}
//               onChange={(e) =>
//                 ctx.setScr({
//                   ...ctx.scr,
//                   options: {
//                     ...ctx.scr.options,
//                     screenType: e.target.value,
//                   },
//                 })
//               }
//             >
//               <MenuItem value={'5.5'}>
//                 5.5" Housing (up to 22' wide & 11' drop)
//               </MenuItem>
//               <MenuItem value={'7'}>
//                 7" Housing (up to 30' wide & 22' drop)
//               </MenuItem>
//             </Select>
//           </FormControl> */}
//           <FormControl
//             className={classes.formControl}
//             fullWidth
//             style={{ opacity: scr.meshOnly ? '0.25' : '1' }}
//           >
//             <InputLabel shrink>Housing</InputLabel>
//             <DynamicAttributes
//               kitAttributes={Housing.attributes}
//               scr={scr}
//               setScr={setScr}
//             />
//           </FormControl>
//           <FormControl
//             className={classes.formControl}
//             fullWidth
//             style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
//           >
//             {ctx.scr.options.jobColor === 'MF' ? (
//               <InputLabel error shrink>
//                 Indicate Job Color in Additional Options.
//               </InputLabel>
//             ) : (
//               <InputLabel shrink>Job Color</InputLabel>
//             )}
//             <Select
//               margin='dense'
//               required={!ctx.scr.meshOnly}
//               disabled={ctx.scr.meshOnly}
//               value={ctx.scr.options.jobColor}
//               onChange={(e) =>
//                 ctx.setScr({
//                   ...ctx.scr,
//                   options: {
//                     ...ctx.scr.options,
//                     jobColor: e.target.value,
//                   },
//                 })
//               }
//             >
//               <MenuItem value={'White'}>White</MenuItem>
//               <MenuItem value={'Black'}>Black</MenuItem>
//               <MenuItem value={'Brown'}>Brown</MenuItem>
//               <MenuItem value={'Tan'}>Tan</MenuItem>
//               <MenuItem value={'Linen'}>Linen</MenuItem>
//               <MenuItem value={'Grey'}>Grey</MenuItem>
//               <MenuItem value={'MF'}>Custom *</MenuItem>
//             </Select>
//           </FormControl>
//           <FormControl className={classes.formControl} fullWidth>
//             {ctx.scr.options.meshType === 'Custom' ? (
//               <InputLabel error shrink>
//                 Indicate Mesh Type in Additional Options.
//               </InputLabel>
//             ) : (
//               <InputLabel shrink>Mesh Type</InputLabel>
//             )}
//             <Select
//               margin='dense'
//               required
//               value={ctx.scr.options?.meshType}
//               onChange={(e) =>
//                 ctx.setScr({
//                   ...ctx.scr,
//                   options: {
//                     ...ctx.scr.options,
//                     meshType: e.target.value,
//                   },
//                 })
//               }
//             >
//               <MenuItem value={'Suntex 80'}>Suntex 80</MenuItem>
//               <MenuItem value={'Suntex 90'}>Suntex 90</MenuItem>
//               <MenuItem value={'Suntex 95'}>Suntex 95</MenuItem>
//               <MenuItem value={'Vinyl'}>Vinyl</MenuItem>
//               <MenuItem value={'TuffScreen'}>
//                 TuffScreen (Black Only)
//               </MenuItem>
//               <MenuItem value={'Custom'}>Custom *</MenuItem>
//             </Select>
//           </FormControl>
//           <FormControl className={classes.formControl} fullWidth>
//             {ctx.scr.options.meshColor === 'Custom' ? (
//               <InputLabel error shrink>
//                 Indicate Mesh Color in Additional Options.
//               </InputLabel>
//             ) : (
//               <InputLabel shrink>Mesh Color</InputLabel>
//             )}
//             <Select
//               margin='dense'
//               required
//               value={ctx.scr.options.meshColor}
//               onChange={(e) =>
//                 ctx.setScr({
//                   ...ctx.scr,
//                   options: {
//                     ...ctx.scr.options,
//                     meshColor: e.target.value,
//                   },
//                 })
//               }
//             >
//               {ctx.scr.options.meshType === 'Custom' && (
//                 <MenuItem value={'Custom'}>Custom *</MenuItem>
//               )}
//               {ctx.scr.options.meshType !== 'Custom' &&
//                 ctx.scr.options.meshType !== 'Vinyl' && (
//                   <MenuItem value={'Black'}>Black</MenuItem>
//                 )}
//               {ctx.scr.options.meshType !== 'Custom' &&
//                 ctx.scr.options.meshType !== 'TuffScreen' &&
//                 ctx.scr.options.meshType !== 'Vinyl' && (
//                   <MenuItem value={'Dark Bronze'}>Dark Bronze</MenuItem>
//                 )}
//               {ctx.scr.options.meshType !== 'Custom' &&
//                 ctx.scr.options.meshType !== 'TuffScreen' &&
//                 ctx.scr.options.meshType !== 'Vinyl' && (
//                   <MenuItem value={'Stucco'}>Stucco</MenuItem>
//                 )}
//               {ctx.scr.options.meshType !== 'Custom' &&
//                 ctx.scr.options.meshType !== 'TuffScreen' &&
//                 ctx.scr.options.meshType !== 'Vinyl' &&
//                 !ctx.scr.options.meshType.includes('95') && (
//                   <MenuItem value={'Beige'}>Beige</MenuItem>
//                 )}
//               {ctx.scr.options.meshType.includes('95') &&
//                 ctx.scr.options.meshType !== 'Vinyl' && (
//                   <MenuItem value={'Alpaca'}>Alpaca</MenuItem>
//                 )}
//               {ctx.scr.options.meshType === 'Vinyl' && (
//                 <MenuItem value={'Clear'}>Clear</MenuItem>
//               )}
//               {ctx.scr.options.meshType === 'Vinyl' && (
//                 <MenuItem value={'Smoked'}>Smoked</MenuItem>
//               )}
//             </Select>
//           </FormControl>
//           {ctx.scr.meshOnly ? (
//             <FormControl className={classes.formControl} fullWidth>
//               <InputLabel shrink>Replacement Reason</InputLabel>
//               <Select
//                 margin='dense'
//                 required={ctx.scr.meshOnly}
//                 disabled={!ctx.scr.meshOnly}
//                 value={ctx.scr.meshOnlyReason}
//                 onChange={(e) =>
//                   ctx.setScr({
//                     ...ctx.scr,
//                     meshOnlyReason: e.target.value,
//                   })
//                 }
//               >
//                 <MenuItem value={'New Purchase'}>New Purchase</MenuItem>
//                 <MenuItem value={'Mesh Defect'}>Mesh Defect</MenuItem>
//                 <MenuItem value={'Zipper Weld Failure'}>
//                   Zipper Weld Failure
//                 </MenuItem>
//                 <MenuItem value={'Ordering Error'}>Ordering Error</MenuItem>
//                 <MenuItem value={'Custom'}>Custom</MenuItem>
//               </Select>
//             </FormControl>
//           ) : (
//             <FormControl className={classes.formControl} fullWidth>
//               <InputLabel shrink>Motor Type</InputLabel>
//               <Select
//                 margin='dense'
//                 required={!ctx.scr.meshOnly}
//                 disabled={ctx.scr.meshOnly}
//                 value={ctx.scr.options.motor}
//                 onChange={(e) =>
//                   ctx.setScr({
//                     ...ctx.scr,
//                     options: {
//                       ...ctx.scr.options,
//                       motor: e.target.value,
//                     },
//                   })
//                 }
//               >
//                 <MenuItem value={'Standard'}>Standard (21 rpm)</MenuItem>
//                 <MenuItem value={'Fast'}>Fast (32 rpm)</MenuItem>
//                 <MenuItem value={'Heavy'}>Heavy</MenuItem>
//               </Select>
//             </FormControl>
//           )}

//           <FormControl
//             className={classes.formControl}
//             fullWidth
//             style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
//           >
//             <InputLabel shrink>Motor Side</InputLabel>
//             <Select
//               margin='dense'
//               required={!ctx.scr.meshOnly}
//               disabled={ctx.scr.meshOnly}
//               value={ctx.scr.options.motorSide}
//               onChange={(e) =>
//                 ctx.setScr({
//                   ...ctx.scr,
//                   options: {
//                     ...ctx.scr.options,
//                     motorSide: e.target.value,
//                   },
//                 })
//               }
//             >
//               <MenuItem value={'Left'}>Left</MenuItem>
//               <MenuItem value={'Right'}>Right</MenuItem>
//             </Select>
//           </FormControl>
//           <Grid item xs={12} className={classes.splitCol}>
//             <FormControl
//               className={classes.formControl}
//               fullWidth
//               style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
//             >
//               <InputLabel shrink>Track Type</InputLabel>
//               <Select
//                 margin='dense'
//                 required={!ctx.scr.meshOnly}
//                 disabled={ctx.scr.meshOnly}
//                 value={ctx.scr.options.tracks}
//                 onChange={(e) =>
//                   ctx.setScr({
//                     ...ctx.scr,
//                     options: {
//                       ...ctx.scr.options,
//                       tracks: e.target.value,
//                     },
//                   })
//                 }
//               >
//                 <MenuItem value={'Standard'}>Standard</MenuItem>
//                 <MenuItem value={'Recessed'}>Recessed</MenuItem>
//               </Select>
//             </FormControl>
//             <FormControl
//               className={classes.formControl}
//               style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
//             >
//               <FormControlLabel
//                 style={{
//                   color: 'rgba(0, 0, 0, 0.54)',
//                   // fontWeight: 400,
//                   // letterSpacing: '0.00938em',
//                 }}
//                 control={
//                   <Switch
//                     disabled={ctx.scr.meshOnly}
//                     checked={ctx.scr.options.mountingAngle}
//                     size='small'
//                     onChange={(e) =>
//                       ctx.setScr({
//                         ...ctx.scr,
//                         options: {
//                           ...ctx.scr.options,
//                           mountingAngle: e.target.checked,
//                         },
//                       })
//                     }
//                   />
//                 }
//                 label='Mnt. Angle?'
//                 labelPlacement='top'
//               />
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} className={classes.splitCol}>
//             <FormControl
//               className={classes.formControl}
//               fullWidth
//               style={{ opacity: ctx.scr.meshOnly ? '0.25' : '1' }}
//             >
//               <InputLabel shrink>Bottom Bar Finish</InputLabel>
//               <Select
//                 margin='dense'
//                 required={!ctx.scr.meshOnly}
//                 disabled={ctx.scr.meshOnly}
//                 value={ctx.scr.options.barFinish}
//                 onChange={(e) =>
//                   ctx.setScr({
//                     ...ctx.scr,
//                     options: {
//                       ...ctx.scr.options,
//                       barFinish: e.target.value,
//                     },
//                   })
//                 }
//               >
//                 <MenuItem value={'2" Rubber'}>2" Rubber</MenuItem>
//                 <MenuItem value={'3" Rubber'}>3" Rubber</MenuItem>
//                 <MenuItem value={'1.5" Brush'}>1.5" Brush</MenuItem>
//                 <MenuItem value={'None'}>None</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//         </Grid>
//         <Grid container item sm={12} md={6}>
//           <Typography style={{ paddingTop: '10px' }}>
//             Measurements
//           </Typography>
//           {ctx.scr.meshOnly && (
//             <InputLabel error shrink>
//               For mesh only orders, please provide the width of the housing
//               instead of the screen.
//             </InputLabel>
//           )}
//           <Grid item xs={12} className={classes.splitCol}>
//             <FormControl className={classes.formControl} fullWidth>
//               <TextField
//                 // margin='dense'
//                 fullWidth
//                 required
//                 type='number'
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 InputProps={{
//                   inputProps: {
//                     min: 36,
//                   },
//                 }}
//                 label='Width (Inches)'
//                 value={Number(ctx.scr.measurements.width)}
//                 onFocus={(e) => e.target.select()}
//                 onChange={(e) =>
//                   ctx.setScr({
//                     ...ctx.scr,
//                     measurements: {
//                       ...ctx.scr.measurements,
//                       width: Number(e.target.value),
//                     },
//                   })
//                 }
//               />
//             </FormControl>
//             <FormControl className={classes.formControl} fullWidth>
//               <InputLabel shrink>(Fraction)</InputLabel>
//               <Select
//                 value={ctx.scr.measurements.widthFraction}
//                 required
//                 fullWidth
//                 onChange={(e) =>
//                   ctx.setScr({
//                     ...ctx.scr,
//                     measurements: {
//                       ...ctx.scr.measurements,
//                       widthFraction: e.target.value,
//                     },
//                   })
//                 }
//               >
//                 <MenuItem value={Number(0)}>0</MenuItem>
//                 <MenuItem value={'1/8'}>1/8</MenuItem>
//                 <MenuItem value={'1/4'}>1/4</MenuItem>
//                 <MenuItem value={'3/8'}>3/8</MenuItem>
//                 <MenuItem value={'1/2'}>1/2</MenuItem>
//                 <MenuItem value={'5/8'}>5/8</MenuItem>
//                 <MenuItem value={'3/4'}>3/4</MenuItem>
//                 <MenuItem value={'7/8'}>7/8</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} className={classes.splitCol}>
//             <FormControl className={classes.formControl} fullWidth>
//               <TextField
//                 fullWidth
//                 required
//                 type='number'
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 InputProps={{
//                   inputProps: {
//                     min: 12,
//                   },
//                 }}
//                 label='Drop Left (Inches)'
//                 value={Number(ctx.scr.measurements.dropLeft)}
//                 onFocus={(e) => e.target.select()}
//                 onChange={(e) =>
//                   ctx.setScr({
//                     ...ctx.scr,
//                     measurements: {
//                       ...ctx.scr.measurements,
//                       dropLeft: Number(e.target.value),
//                     },
//                   })
//                 }
//               />
//             </FormControl>
//             <FormControl className={classes.formControl} fullWidth>
//               <InputLabel shrink>(Fraction)</InputLabel>
//               <Select
//                 value={ctx.scr.measurements.dropLeftFraction}
//                 required
//                 fullWidth
//                 onChange={(e) =>
//                   ctx.setScr({
//                     ...ctx.scr,
//                     measurements: {
//                       ...ctx.scr.measurements,
//                       dropLeftFraction: e.target.value,
//                     },
//                   })
//                 }
//               >
//                 <MenuItem value={'0'}>0</MenuItem>
//                 <MenuItem value={'1/4'}>1/4</MenuItem>
//                 <MenuItem value={'1/2'}>1/2</MenuItem>
//                 <MenuItem value={'3/4'}>3/4</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} className={classes.splitCol}>
//             <FormControl className={classes.formControl} fullWidth>
//               <TextField
//                 fullWidth
//                 required
//                 type='number'
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 InputProps={{
//                   inputProps: {
//                     min: 12,
//                   },
//                 }}
//                 label='Drop Right (Inches)'
//                 value={Number(ctx.scr.measurements.dropRight)}
//                 onFocus={(e) => e.target.select()}
//                 onChange={(e) =>
//                   ctx.setScr({
//                     ...ctx.scr,
//                     measurements: {
//                       ...ctx.scr.measurements,
//                       dropRight: Number(e.target.value),
//                     },
//                   })
//                 }
//               />
//             </FormControl>
//             <FormControl className={classes.formControl} fullWidth>
//               <InputLabel shrink>(Fraction)</InputLabel>
//               <Select
//                 value={ctx.scr.measurements.dropRightFraction}
//                 required
//                 fullWidth
//                 onChange={(e) =>
//                   ctx.setScr({
//                     ...ctx.scr,
//                     measurements: {
//                       ...ctx.scr.measurements,
//                       dropRightFraction: e.target.value,
//                     },
//                   })
//                 }
//               >
//                 <MenuItem value={'0'}>0</MenuItem>
//                 <MenuItem value={'1/4'}>1/4</MenuItem>
//                 <MenuItem value={'1/2'}>1/2</MenuItem>
//                 <MenuItem value={'3/4'}>3/4</MenuItem>
//               </Select>
//             </FormControl>
//           </Grid>
//           <Grid item xs={12} className={classes.splitCol}>
//             <DropDirection
//               l={ctx.scr.measurements.dropLeft || ''}
//               lf={ctx.scr.measurements.dropLeftFraction || ''}
//               r={ctx.scr.measurements.dropRight || ''}
//               rf={ctx.scr.measurements.dropRightFraction || ''}
//             />
//             {/* {dropDirection(
//               ctx.scr.measurements.dropLeft,
//               ctx.scr.measurements.dropLeftFraction,
//               ctx.scr.measurements.dropRight,
//               ctx.scr.measurements.dropRightFraction
//             )} */}
//           </Grid>
//           <Grid item xs={12} className={classes.splitCol}>
//             <FormControl className={classes.formControl}>
//               <TextField
//                 fullWidth
//                 required
//                 value={Number(ctx.scr.cartCopies)}
//                 type='number'
//                 variant='outlined'
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 InputProps={{
//                   inputProps: {
//                     min: 1,
//                   },
//                 }}
//                 label='Screen Copies'
//                 onFocus={(e) => e.target.select()}
//                 onChange={(e) =>
//                   ctx.setScr({
//                     ...ctx.scr,
//                     cartCopies: Number(e.target.value),
//                   })
//                 }
//               />
//             </FormControl>
//           </Grid>
//           <Box>
//             <Typography color='error' variant='subtitle2'>
//               <em>
//                 All additional items (remotes, angle for top slope, etc.)
//                 need to be ordered separately from the Parts & Extras tab.
//               </em>
//             </Typography>
//           </Box>
//           <FormControl
//             className={classes.formControl}
//             required
//             fullWidth
//             style={{ width: '100%' }}
//           >
//             <TextField
//               fullWidth
//               variant='outlined'
//               multiline
//               minRows={6}
//               type='text'
//               InputLabelProps={{
//                 shrink: true,
//               }}
//               label='Additional Details'
//               value={ctx.scr.details}
//               onFocus={(e) => e.target.select()}
//               onChange={(e) =>
//                 ctx.setScr({ ...ctx.scr, details: e.target.value })
//               }
//             />
//           </FormControl>
//         </Grid>
//         <Button
//           type='submit'
//           className={classes.submit}
//           fullWidth
//           variant='contained'
//           color='primary'
//           disabled={ctx.isAdmin && order && status === 'Rejected'}
//           size='medium'
//         >
//           {order || ctx.scr.screenId.length > 0
//             ? 'Update Order'
//             : 'Add to Order'}
//         </Button>
//       </Grid>
//     </Grid>
//   </form>
// </div>