import axios from 'axios';
import { toast } from 'react-toastify';

// constant imports
import {
  ADMIN_LIST_USERS_REQUEST,
  ADMIN_LIST_USERS_SUCCESS,
  ADMIN_LIST_USERS_FAIL,
  ADMIN_LIST_ORDERS_REQUEST,
  ADMIN_LIST_ORDERS_SUCCESS,
  ADMIN_LIST_ORDERS_FAIL,
  ADMIN_UPDATE_USER_REQUEST,
  ADMIN_UPDATE_USER_SUCCESS,
  ADMIN_UPDATE_USER_FAIL,
  ADMIN_UPDATE_USERS_REQUEST,
  ADMIN_UPDATE_USERS_SUCCESS,
  ADMIN_UPDATE_USERS_FAIL,
  ADMIN_LIST_BCCUSTOMERS_REQUEST,
  ADMIN_LIST_BCCUSTOMERS_SUCCESS,
  ADMIN_LIST_BCCUSTOMERS_FAIL,
  ADMIN_LIST_TICKETS_REQUEST,
  ADMIN_LIST_TICKETS_SUCCESS,
  ADMIN_LIST_TICKETS_FAIL,
  ADMIN_PORTALPRICING_REQUEST,
  ADMIN_PORTALPRICING_SUCCESS,
  ADMIN_PORTALPRICING_FAIL,
  ADMIN_SAVE_PORTALPRICING_REQUEST,
  ADMIN_SAVE_PORTALPRICING_SUCCESS,
  ADMIN_SAVE_PORTALPRICING_FAIL,
  ADMIN_UPDATE_BC_CUSTOMERS_REQUEST,
  ADMIN_UPDATE_BC_CUSTOMERS_SUCCESS,
  ADMIN_UPDATE_BC_CUSTOMERS_FAIL,
  ADMIN_CUSTOMER_TIER_REQUEST,
  ADMIN_CUSTOMER_TIER_SUCCESS,
  ADMIN_CUSTOMER_TIER_FAIL,
} from '../constants';

export const adminUserList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_LIST_USERS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await axios.get('/api/admin', config);
    dispatch({
      type: ADMIN_LIST_USERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_LIST_USERS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminBcCustomerList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_LIST_BCCUSTOMERS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await axios.get('/api/bc/customers', config);
    dispatch({
      type: ADMIN_LIST_BCCUSTOMERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_LIST_BCCUSTOMERS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminUpdateBcCustomers =
  (editedBcCustomers) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_BC_CUSTOMERS_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
        proxy: {
          host: 'localhost',
          port: 5000,
        },
      };

      const bcCustomersToUpdate = editedBcCustomers.map((bcCustomer) => {
        return {
          bc_customer_id: bcCustomer.bc_customer_id,
          customerTier: bcCustomer.customerTier._id,
        };
      });

      const { data } = await toast.promise(
        axios.put('/api/admin/customers', { bcCustomersToUpdate }, config),
        {
          pending: 'BC Customers saving...',
          success: {
            render() {
              return 'BC Customers saved.';
            },
          },
          error: 'BC Customers not saved.',
        }
      );
      dispatch({
        type: ADMIN_UPDATE_BC_CUSTOMERS_SUCCESS,
        payload: { data },
      });
    } catch (err) {
      dispatch({
        type: ADMIN_UPDATE_BC_CUSTOMERS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const adminUpdateUsers = (editedUsers) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_USERS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };

    const usersToUpdate = editedUsers.map((user) => {
      return {
        _id: user._id,
        bc_customer_id: user.bc_customer_id,
        isApproved: user.isApproved,
        storeAccess: user.storeAccess,
        isAdmin: user.isAdmin,
        isCorporation: user.isCorporation,
      };
    });

    // console.log('editedUsers', editedUsers);
    // console.log('usersToUpdate', usersToUpdate);

    const { data } = await toast.promise(
      axios.put('/api/admin', { usersToUpdate }, config),
      {
        pending: 'Users saving...',
        success: {
          render() {
            return 'Users saved.';
          },
        },
        error: 'Users not saved.',
      }
    );
    dispatch({
      type: ADMIN_UPDATE_USERS_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    dispatch({
      type: ADMIN_UPDATE_USERS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminUpdateUser =
  (_id, bc_customer_id, isApproved, isAdmin) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_USER_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const {
        adminUserList: { users },
      } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
        proxy: {
          host: 'localhost',
          port: 5000,
        },
      };
      const { data } = await toast.promise(
        axios.put(
          '/api/admin',
          { _id, bc_customer_id, isApproved, isAdmin },
          config
        ),
        {
          pending: 'User saving...',
          success: {
            render({ data }) {
              return `Portal user: ${data.data.fname} ${data.data.lname} saved.`;
            },
          },
          error: 'User not saved.',
        }
      );
      dispatch({
        type: ADMIN_UPDATE_USER_SUCCESS,
        payload: { data, users },
      });
    } catch (err) {
      dispatch({
        type: ADMIN_UPDATE_USER_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const adminListAllOrders =
  (limit, offset, status, filter) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_LIST_ORDERS_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
        proxy: {
          host: 'localhost',
          port: 5000,
        },
      };
      let apiUrl = '/api/admin/orders';
      apiUrl += `?limit=${limit}`;
      if (status !== '') {
        apiUrl += `&status=${status}`;
      }

      apiUrl += `&offset=${offset}`;
      apiUrl += `&filter=${filter}`;

       console.log('apiUrl', apiUrl);

      const { data } = await axios.get(apiUrl, config);

      dispatch({
        type: ADMIN_LIST_ORDERS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ADMIN_LIST_ORDERS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const adminListAllTickets = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_LIST_TICKETS_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await axios.get('/api/admin/tickets', config);
    dispatch({
      type: ADMIN_LIST_TICKETS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_LIST_TICKETS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminGetPortalPricing = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_PORTALPRICING_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await axios.get('/api/admin/portalpricing', config);
    dispatch({
      type: ADMIN_PORTALPRICING_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_PORTALPRICING_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminGetCustomerTiers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_CUSTOMER_TIER_REQUEST,
    });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await axios.get('/api/admin/customertiers', config);
    dispatch({
      type: ADMIN_CUSTOMER_TIER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_CUSTOMER_TIER_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminSavePortalPricing =
  (pricing) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_SAVE_PORTALPRICING_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const res = await axios.post('/api/admin/portalpricing', pricing, config);
      dispatch({
        type: ADMIN_SAVE_PORTALPRICING_SUCCESS,
        payload: {
          portalPricing: res.data,
          status: res.status,
          statusText: res.statusText,
        },
      });
    } catch (err) {
      dispatch({
        type: ADMIN_SAVE_PORTALPRICING_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const adminImportCustomers = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await toast.promise(
      axios.get('/api/admin/import/customers', config),
      {
        pending: 'Importing customers from BC...',
        success: 'Customers import successful!',
        error: 'Customers import failed.',
      }
    );
  } catch (err) {
    console.log(
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    );
  }
};

export const adminImportItems = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await toast.promise(
      axios.get('/api/admin/import/items', config),
      {
        pending: 'Importing items from BC...',
        success: 'Items import successful!',
        error: 'Items import failed.',
      }
    );
  } catch (err) {
    console.log(
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    );
  }
};

export const adminImportPrices = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
      proxy: {
        host: 'localhost',
        port: 5000,
      },
    };
    const { data } = await toast.promise(
      axios.get('/api/admin/import/prices', config),
      {
        pending: 'Importing prices from BC...',
        success: 'Prices import successful!',
        error: 'Prices import failed.',
      }
    );
  } catch (err) {
    console.log(
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    );
  }
};
